body {
  margin: 0;
  line-height: normal;
}

:root {
 /* fonts */
 --font: "Open Sans";

 /* font sizes */
 --mpc-1-font-size: 64px;
 --mpc-1-font1-size: 32px;
 --mpc-1-font2-size: 24px;
 --mpc-1-font3-size: 20px;
 --mpc-1-font4-size: 28px;
 --mpc-1-font5-size: 124px;

 /* Colors */
 --mpc-1-dark-t1: #fff;
 --mpc-1-dark-b1: #121212;
 --mpc-1-dark-tanglepay: #3671ee;
 --mpc-1-light-b5-day: #f2f2f6;

 /* Paddings */
 --mpc-1-padding-5xs: 8px;
 --mpc-1-padding-base: 16px;

 /* border radiuses */
 --mpc-1-br-base: 16px;
 --mpc-1-br-5xs: 8px;

 /* font sizes */
 --mpc-2-font-size: 48px;
 --mpc-2-font1-size: 28px;
 --mpc-2-font2-size: 64px;
 --mpc-2-font3-size: 32px;
 --mpc-2-font4-size: 24px;
 --mpc-2-font5-size: 20px;
 --mpc-2-font6-size: 124px;

 /* Colors */
 --mpc-2-dark-t1: #fff;
 --mpc-2-dark-b1: #121212;
 --mpc-2-light-t5: #ddd;
 --mpc-2-dark-tanglepay: #3671ee;
 --mpc-2-light-t1-day: #333;
 --mpc-2-light-b5-day: #f2f2f6;

 /* Paddings */
 --mpc-2-padding-5xs: 8px;
 --mpc-2-padding-base: 16px;

 /* border radiuses */
 --mpc-2-br-9xs: 4px;
 --mpc-2-br-5xs: 8px;
 --mpc-2-br-base: 16px;

 --mpc-3-font: "Open Sans";

  /* font sizes */
  --mpc-3-font-size: 124px;
  --mpc-3-font1-size: 28px;
  --mpc-3-font2-size: 40px;
  --mpc-3-font3-size: 32px;
  --mpc-3-font4-size: 48px;

  /* Colors */
  --mpc-3-dark-t1: #fff;
  --mpc-3-light-t1-day: #333;
  --mpc-3-dark-b1: #121212;
  --mpc-3-dark-tanglepay: #3671ee;
  --mpc-3-color-black: #000;
  --mpc-3-light-t5: #ddd;

  /* Gaps */
  --mpc-3-gap-13xl: 32px;

  /* Paddings */
  --mpc-3-padding-21xl: 40px;

  /* Border radiuses */
  --mpc-3-br-9xl: 28px;
  --mpc-3-br-9xs: 4px;

  /* Effects */
  --mpc-3-effect: 8px 8px 32px rgba(0, 0, 0, 0.12);
  --mpc-3-effect1: 2px 2px 8px rgba(0, 0, 0, 0.16);
  --mpc-3-validator: 16px 16px 16px 16px rgba(0, 0, 0, 0.04);

  /* font sizes */
  --mpc-4-font2-size: 32px;
  --mpc-4-font-size: 28px;
  --mpc-4-font3-size: 48px;
  --mpc-4-font4-size: 40px;
  --mpc-4-font1-size: 124px;
  --mpc-4-font5-size: 64px;
  --mpc-4-font6-size: 24px;
  --mpc-4-font7-size: 20px;

  /* Colors */
  --mpc-4-dark-t1: #fff;
  --mpc-4-dark-tanglepay: #3671ee;
  --mpc-4-color-black: #000;
  --mpc-4-dark-b1: #121212;
  --mpc-4-light-t1-day: #333;
  --mpc-4-light-t5: #ddd;
  --mpc-4-light-b5-day: #f2f2f6;

  /* Gaps */
  --mpc-4-gap-13xl: 32px;
  --mpc-4-gap-17xl: 36px;

  /* Paddings */
  --mpc-4-padding-21xl: 40px;
  --mpc-4-padding-29xl: 48px;
  --mpc-4-padding-39xl: 58px;
  --mpc-4-padding-5xs: 8px;
  --mpc-4-padding-base: 16px;

  /* border radiuses */
  --mpc-4-br-base: 16px;
  --mpc-4-br-9xl: 28px;
  --mpc-4-br-5xs: 8px;
  --mpc-4-br-9xs: 4px;

  /* Effects */
  --mpc-4-validator: 16px 16px 16px 16px rgba(0, 0, 0, 0.04);

  /* font sizes */
  --mpc-5-font-size: 28px;
  --mpc-5-font1-size: 40px;
  --mpc-5-font2-size: 29px;
  --mpc-5-font3-size: 48px;
  --mpc-5-font4-size: 124px;
  --mpc-5-font5-size: 64px;
  --mpc-5-font6-size: 24px;
  --mpc-5-font7-size: 20px;

  /* Colors */
  --mpc-5-dark-t1: #fff;
  --mpc-5-light-t5: #ddd;
  --mpc-5-dark-tanglepay: #3671ee;
  --mpc-5-dark-b1: #121212;
  --mpc-5-light-t1-day: #333;
  --mpc-5-color-black: #000;
  --mpc-5-light-b5-day: #f2f2f6;

  /* Gaps */
  --mpc-5-gap-13xl: 32px;
  --mpc-5-gap-17xl: 36px;

  /* Paddings */
  --mpc-5-padding-21xl: 40px;
  --mpc-5-padding-29xl: 48px;
  --mpc-5-padding-39xl: 58px;
  --mpc-5-padding-39xl-1: 38px;
  --mpc-5-padding-5xs: 8px;
  --mpc-5-padding-base: 16px;

  /* border radiuses */
  --mpc-5-br-9xs: 4px;
  --mpc-5-br-9xl: 28px;
  --mpc-5-br-base: 16px;
  --mpc-5-br-5xs: 8px;

  /* Effects */
  --mpc-5-validator: 16px 16px 16px 16px rgba(0, 0, 0, 0.04);

  /* font sizes */
  --mpc-6-font-size: 14px;
  --mpc-6-font1-size: 16px;
  --mpc-6-font2-size: 32px;
  --mpc-6-font3-size: 48px;
  --mpc-6-font4-size: 40px;
  --mpc-6-font5-size: 28px;
  --mpc-6-font6-size: 124px;
  --mpc-6-font7-size: 64px;
  --mpc-6-font8-size: 24px;
  --mpc-6-font9-size: 20px;

  /* Colors */
  --mpc-6-dark-b1: #121212;
  --mpc-6-dark-t2: rgba(255, 255, 255, 0.6);
  --mpc-6-light-t2-day: #6c737c;
  --mpc-6-light-t1-day: #333;
  --mpc-6-dark-tanglepay: #3671ee;
  --mpc-6-dark-t1: #fff;
  --mpc-6-light-t5: #ddd;
  --mpc-6-color-black: #000;
  --mpc-6-light-b5-day: #f2f2f6;

  /* Gaps */
  --mpc-6-gap-17xl: 36px;
  --mpc-6-gap-13xl: 32px;

  /* Paddings */
  --mpc-6-padding-8xs: 5px;
  --mpc-6-padding-12xs: 1px;
  --mpc-6-padding-6xs-3: 6.3px;
  --mpc-6-padding-29xl: 48px;
  --mpc-6-padding-39xl: 58px;
  --mpc-6-padding-21xl: 40px;
  --mpc-6-padding-5xs: 8px;
  --mpc-6-padding-base: 16px;

  /* border radiuses */
  --mpc-6-br-smi: 13px;
  --mpc-6-br-5xs: 8px;
  --mpc-6-br-9xl: 28px;
  --mpc-6-br-9xs: 4px;
  --mpc-6-br-base: 16px;

  /* Effects */
  --mpc-6-validator: 16px 16px 16px 16px rgba(0, 0, 0, 0.04);

  --br-1-font-size: 20px;
  --br-1-font1-size: 28px;
  --br-1-font2-size: 124px;
  --br-1-font3-size: 24px;

  /* Colors */
  --br-1-dark-t1: #fff;
  --br-1-light-b5-day: #f2f2f6;
  --br-1-dark-tanglepay: #3671ee;
  --br-1-dark-b1: #121212;

  /* Paddings */
  --br-1-padding-5xs: 8px;
  --br-1-padding-base: 16px;

  /* border radiuses */
  --br-1-br-5xs: 8px;

  --br-2-font: "Open Sans";

  /* font sizes */
  --br-2-font-size: 64px;
  --br-2-font1-size: 28px;
  --br-2-font2-size: 48px;

  /* Colors */
  --br-2-dark-t1: #fff;
  --br-2-dark-b1: #121212;
  --br-2-color-gray-100: rgba(0, 0, 0, 0.05);
  --br-2-light-t5: #ddd;
  --br-2-dark-tanglepay: #3671ee;
  --br-2-color-black: #000;
  --br-2-light-t4-day: #959595;

  /* Gaps */
  --br-2-gap-xs: 12px;

  /* Paddings */
  --br-2-padding-17xl: 36px;

  /* Border radiuses */
  --br-2-br-13xl: 32px;
  --br-2-br-9xs: 4px;
  --br-2-br-9xl: 28px;
  --br-2-br-116xl: 135px;

  /* Effects */
  --br-2-effect: 4px 4px 24px rgba(0, 0, 0, 0.12);


  /* font sizes */
  --br-3-font-size: 40px;
  --br-3-font3-size: 28px;
  --br-3-font1-size: 48px;
  --br-3-font2-size: 64px;

  /* Colors */
  --br-3-dark-t1: #fff;
  --br-3-dark-tanglepay: #3671ee;
  --br-3-light-t5: #ddd;
  --br-3-dark-b1: #121212;
  --br-3-color-gray-100: rgba(0, 0, 0, 0.05);
  --br-3-light-t1-day: #333;
  --br-3-color-black: #000;

  /* Gaps */
  --br-3-gap-13xl: 32px;
  --br-3-gap-xl: 20px;
  --br-3-gap-xs: 12px;

  /* Paddings */
  --br-3-padding-13xl: 32px;
  --br-3-padding-21xl: 40px;
  --br-3-padding-9xl: 28px;
  --br-3-padding-17xl: 36px;

  /* border radiuses */
  --br-3-br-9xs: 4px;
  --br-3-br-9xl: 28px;
  --br-3-br-116xl: 135px;


  /* font sizes */
  --vd-1-font-size: 20px;
  --vd-1-font1-size: 28px;
  --vd-1-font2-size: 24px;
  --vd-1-font3-size: 64px;
  --vd-1-font4-size: 124px;

  /* Colors */
  --vd-1-dark-t1: #fff;
  --vd-1-dark-tanglepay: #3671ee;
  --vd-1-dark-b1: #121212;
  --vd-1-dark-green: #107516;
  --vd-1-light-b5-day: #f2f2f6;

  /* Gaps */
  --vd-1-gap-base: 16px;

  /* Paddings */
  --vd-1-padding-21xl: 40px;
  --vd-1-padding-5xs: 8px;
  --vd-1-padding-base: 16px;

  /* border radiuses */
  --vd-1-br-5xs: 8px;
  --vd-1-br-9xl: 28px;

  /* font sizes */
  --vd-2-font-size: 32px;
  --vd-2-font1-size: 48px;
  --vd-2-font2-size: 20px;
  --vd-2-font3-size: 28px;
  --vd-2-font4-size: 24px;
  --vd-2-font5-size: 64px;
  --vd-2-font6-size: 124px;

  /* Colors */
  --vd-2-dark-t1: #fff;
  --vd-2-dark-b1: #121212;
  --vd-2-light-t4-day: #959595;
  --vd-2-dark-tanglepay: #3671ee;
  --vd-2-dark-green: #107516;
  --vd-2-light-b5-day: #f2f2f6;

  /* Gaps */
  --vd-2-gap-base: 16px;

  /* Paddings */
  --vd-2-padding-21xl: 40px;
  --vd-2-padding-5xs: 8px;
  --vd-2-padding-base: 16px;

  /* border radiuses */
  --vd-2-br-5xs: 8px;
  --vd-2-br-9xl: 28px;
}

.container {
  position: relative;
  overflow: hidden;
  background-color: var(--dark-t1);
  width: 100%;
  text-align: center;
}
a.no-effects {
  color: inherit; /* Reset text color */
  text-decoration: none; /* Remove underline */
}

a.no-effects:hover,
a.no-effects:focus,
a.no-effects:active {
  color: inherit; /* Reset hover, focus, and active text color */
  text-decoration: none; /* Remove hover, focus, and active underline */
}

.hover-ban {
  position: relative;
  display: inline-block;
}

.hover-ban::before {
  content: url('/image/mpc/pc/redban.svg');
  position: absolute;
  display: none;
  left: -6px;
  top: 100%;
  margin-left: 10px;
  width: 24px;
  height: 24px;
}

.hover-ban:hover::before {
  display: block;
}