body {
  margin: 0;
  line-height: normal;
}

:root {
  --font: "Open Sans";
  --mpc-1-font-size: 3.33333vw;
  --mpc-1-font1-size: 1.66667vw;
  --mpc-1-font2-size: 1.25vw;
  --mpc-1-font3-size: 1.04167vw;
  --mpc-1-font4-size: 1.45833vw;
  --mpc-1-font5-size: 6.45833vw;
  --mpc-1-dark-t1: #fff;
  --mpc-1-dark-b1: #121212;
  --mpc-1-dark-tanglepay: #3671ee;
  --mpc-1-light-b5-day: #f2f2f6;
  --mpc-1-padding-5xs: .41667vw;
  --mpc-1-padding-base: .83333vw;
  --mpc-1-br-base: .83333vw;
  --mpc-1-br-5xs: .41667vw;
  --mpc-2-font-size: 2.5vw;
  --mpc-2-font1-size: 1.45833vw;
  --mpc-2-font2-size: 3.33333vw;
  --mpc-2-font3-size: 1.66667vw;
  --mpc-2-font4-size: 1.25vw;
  --mpc-2-font5-size: 1.04167vw;
  --mpc-2-font6-size: 6.45833vw;
  --mpc-2-dark-t1: #fff;
  --mpc-2-dark-b1: #121212;
  --mpc-2-light-t5: #ddd;
  --mpc-2-dark-tanglepay: #3671ee;
  --mpc-2-light-t1-day: #333;
  --mpc-2-light-b5-day: #f2f2f6;
  --mpc-2-padding-5xs: .41667vw;
  --mpc-2-padding-base: .83333vw;
  --mpc-2-br-9xs: .20833vw;
  --mpc-2-br-5xs: .41667vw;
  --mpc-2-br-base: .83333vw;
  --mpc-3-font: "Open Sans";
  --mpc-3-font-size: 6.45833vw;
  --mpc-3-font1-size: 1.45833vw;
  --mpc-3-font2-size: 2.08333vw;
  --mpc-3-font3-size: 1.66667vw;
  --mpc-3-font4-size: 2.5vw;
  --mpc-3-dark-t1: #fff;
  --mpc-3-light-t1-day: #333;
  --mpc-3-dark-b1: #121212;
  --mpc-3-dark-tanglepay: #3671ee;
  --mpc-3-color-black: #000;
  --mpc-3-light-t5: #ddd;
  --mpc-3-gap-13xl: 1.66667vw;
  --mpc-3-padding-21xl: 2.08333vw;
  --mpc-3-br-9xl: 1.45833vw;
  --mpc-3-br-9xs: .20833vw;
  --mpc-3-effect: .41667vw .41667vw 1.66667vw #0000001f;
  --mpc-3-effect1: .10417vw .10417vw .41667vw #00000029;
  --mpc-3-validator: .83333vw .83333vw .83333vw .83333vw #0000000a;
  --mpc-4-font2-size: 1.66667vw;
  --mpc-4-font-size: 1.45833vw;
  --mpc-4-font3-size: 2.5vw;
  --mpc-4-font4-size: 2.08333vw;
  --mpc-4-font1-size: 6.45833vw;
  --mpc-4-font5-size: 3.33333vw;
  --mpc-4-font6-size: 1.25vw;
  --mpc-4-font7-size: 1.04167vw;
  --mpc-4-dark-t1: #fff;
  --mpc-4-dark-tanglepay: #3671ee;
  --mpc-4-color-black: #000;
  --mpc-4-dark-b1: #121212;
  --mpc-4-light-t1-day: #333;
  --mpc-4-light-t5: #ddd;
  --mpc-4-light-b5-day: #f2f2f6;
  --mpc-4-gap-13xl: 1.66667vw;
  --mpc-4-gap-17xl: 1.875vw;
  --mpc-4-padding-21xl: 2.08333vw;
  --mpc-4-padding-29xl: 2.5vw;
  --mpc-4-padding-39xl: 3.02083vw;
  --mpc-4-padding-5xs: .41667vw;
  --mpc-4-padding-base: .83333vw;
  --mpc-4-br-base: .83333vw;
  --mpc-4-br-9xl: 1.45833vw;
  --mpc-4-br-5xs: .41667vw;
  --mpc-4-br-9xs: .20833vw;
  --mpc-4-validator: .83333vw .83333vw .83333vw .83333vw #0000000a;
  --mpc-5-font-size: 1.45833vw;
  --mpc-5-font1-size: 2.08333vw;
  --mpc-5-font2-size: 1.51042vw;
  --mpc-5-font3-size: 2.5vw;
  --mpc-5-font4-size: 6.45833vw;
  --mpc-5-font5-size: 3.33333vw;
  --mpc-5-font6-size: 1.25vw;
  --mpc-5-font7-size: 1.04167vw;
  --mpc-5-dark-t1: #fff;
  --mpc-5-light-t5: #ddd;
  --mpc-5-dark-tanglepay: #3671ee;
  --mpc-5-dark-b1: #121212;
  --mpc-5-light-t1-day: #333;
  --mpc-5-color-black: #000;
  --mpc-5-light-b5-day: #f2f2f6;
  --mpc-5-gap-13xl: 1.66667vw;
  --mpc-5-gap-17xl: 1.875vw;
  --mpc-5-padding-21xl: 2.08333vw;
  --mpc-5-padding-29xl: 2.5vw;
  --mpc-5-padding-39xl: 3.02083vw;
  --mpc-5-padding-39xl-1: 1.97917vw;
  --mpc-5-padding-5xs: .41667vw;
  --mpc-5-padding-base: .83333vw;
  --mpc-5-br-9xs: .20833vw;
  --mpc-5-br-9xl: 1.45833vw;
  --mpc-5-br-base: .83333vw;
  --mpc-5-br-5xs: .41667vw;
  --mpc-5-validator: .83333vw .83333vw .83333vw .83333vw #0000000a;
  --mpc-6-font-size: .72917vw;
  --mpc-6-font1-size: .83333vw;
  --mpc-6-font2-size: 1.66667vw;
  --mpc-6-font3-size: 2.5vw;
  --mpc-6-font4-size: 2.08333vw;
  --mpc-6-font5-size: 1.45833vw;
  --mpc-6-font6-size: 6.45833vw;
  --mpc-6-font7-size: 3.33333vw;
  --mpc-6-font8-size: 1.25vw;
  --mpc-6-font9-size: 1.04167vw;
  --mpc-6-dark-b1: #121212;
  --mpc-6-dark-t2: #fff9;
  --mpc-6-light-t2-day: #6c737c;
  --mpc-6-light-t1-day: #333;
  --mpc-6-dark-tanglepay: #3671ee;
  --mpc-6-dark-t1: #fff;
  --mpc-6-light-t5: #ddd;
  --mpc-6-color-black: #000;
  --mpc-6-light-b5-day: #f2f2f6;
  --mpc-6-gap-17xl: 1.875vw;
  --mpc-6-gap-13xl: 1.66667vw;
  --mpc-6-padding-8xs: .26042vw;
  --mpc-6-padding-12xs: 1px;
  --mpc-6-padding-6xs-3: .32813vw;
  --mpc-6-padding-29xl: 2.5vw;
  --mpc-6-padding-39xl: 3.02083vw;
  --mpc-6-padding-21xl: 2.08333vw;
  --mpc-6-padding-5xs: .41667vw;
  --mpc-6-padding-base: .83333vw;
  --mpc-6-br-smi: .67708vw;
  --mpc-6-br-5xs: .41667vw;
  --mpc-6-br-9xl: 1.45833vw;
  --mpc-6-br-9xs: .20833vw;
  --mpc-6-br-base: .83333vw;
  --mpc-6-validator: .83333vw .83333vw .83333vw .83333vw #0000000a;
  --br-1-font-size: 1.04167vw;
  --br-1-font1-size: 1.45833vw;
  --br-1-font2-size: 6.45833vw;
  --br-1-font3-size: 1.25vw;
  --br-1-dark-t1: #fff;
  --br-1-light-b5-day: #f2f2f6;
  --br-1-dark-tanglepay: #3671ee;
  --br-1-dark-b1: #121212;
  --br-1-padding-5xs: .41667vw;
  --br-1-padding-base: .83333vw;
  --br-1-br-5xs: .41667vw;
  --br-2-font: "Open Sans";
  --br-2-font-size: 3.33333vw;
  --br-2-font1-size: 1.45833vw;
  --br-2-font2-size: 2.5vw;
  --br-2-dark-t1: #fff;
  --br-2-dark-b1: #121212;
  --br-2-color-gray-100: #0000000d;
  --br-2-light-t5: #ddd;
  --br-2-dark-tanglepay: #3671ee;
  --br-2-color-black: #000;
  --br-2-light-t4-day: #959595;
  --br-2-gap-xs: .625vw;
  --br-2-padding-17xl: 1.875vw;
  --br-2-br-13xl: 1.66667vw;
  --br-2-br-9xs: .20833vw;
  --br-2-br-9xl: 1.45833vw;
  --br-2-br-116xl: 7.03125vw;
  --br-2-effect: .20833vw .20833vw 1.25vw #0000001f;
  --br-3-font-size: 2.08333vw;
  --br-3-font3-size: 1.45833vw;
  --br-3-font1-size: 2.5vw;
  --br-3-font2-size: 3.33333vw;
  --br-3-dark-t1: #fff;
  --br-3-dark-tanglepay: #3671ee;
  --br-3-light-t5: #ddd;
  --br-3-dark-b1: #121212;
  --br-3-color-gray-100: #0000000d;
  --br-3-light-t1-day: #333;
  --br-3-color-black: #000;
  --br-3-gap-13xl: 1.66667vw;
  --br-3-gap-xl: 1.04167vw;
  --br-3-gap-xs: .625vw;
  --br-3-padding-13xl: 1.66667vw;
  --br-3-padding-21xl: 2.08333vw;
  --br-3-padding-9xl: 1.45833vw;
  --br-3-padding-17xl: 1.875vw;
  --br-3-br-9xs: .20833vw;
  --br-3-br-9xl: 1.45833vw;
  --br-3-br-116xl: 7.03125vw;
  --vd-1-font-size: 1.04167vw;
  --vd-1-font1-size: 1.45833vw;
  --vd-1-font2-size: 1.25vw;
  --vd-1-font3-size: 3.33333vw;
  --vd-1-font4-size: 6.45833vw;
  --vd-1-dark-t1: #fff;
  --vd-1-dark-tanglepay: #3671ee;
  --vd-1-dark-b1: #121212;
  --vd-1-dark-green: #107516;
  --vd-1-light-b5-day: #f2f2f6;
  --vd-1-gap-base: .83333vw;
  --vd-1-padding-21xl: 2.08333vw;
  --vd-1-padding-5xs: .41667vw;
  --vd-1-padding-base: .83333vw;
  --vd-1-br-5xs: .41667vw;
  --vd-1-br-9xl: 1.45833vw;
  --vd-2-font-size: 1.66667vw;
  --vd-2-font1-size: 2.5vw;
  --vd-2-font2-size: 1.04167vw;
  --vd-2-font3-size: 1.45833vw;
  --vd-2-font4-size: 1.25vw;
  --vd-2-font5-size: 3.33333vw;
  --vd-2-font6-size: 6.45833vw;
  --vd-2-dark-t1: #fff;
  --vd-2-dark-b1: #121212;
  --vd-2-light-t4-day: #959595;
  --vd-2-dark-tanglepay: #3671ee;
  --vd-2-dark-green: #107516;
  --vd-2-light-b5-day: #f2f2f6;
  --vd-2-gap-base: .83333vw;
  --vd-2-padding-21xl: 2.08333vw;
  --vd-2-padding-5xs: .41667vw;
  --vd-2-padding-base: .83333vw;
  --vd-2-br-5xs: .41667vw;
  --vd-2-br-9xl: 1.45833vw;
}

.container {
  background-color: var(--dark-t1);
  width: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
}

a.no-effects, a.no-effects:hover, a.no-effects:focus, a.no-effects:active {
  color: inherit;
  text-decoration: none;
}

.hover-ban {
  display: inline-block;
  position: relative;
}

.hover-ban:before {
  content: url("redban.07a48f27.svg");
  width: 1.25vw;
  height: 1.25vw;
  margin-left: .52083vw;
  display: none;
  position: absolute;
  top: 100%;
  left: -.3125vw;
}

.hover-ban:hover:before {
  display: block;
}

/*# sourceMappingURL=mpc.e05d324c.css.map */
